<template>
  <div id="entradas" class="mt-4">
    <h4 class="text-primary mt-3 mb-3 font-weight-bold">Entradas</h4>
    <div class="mb-3 text-right">
      <button
        v-if="perfil == 1"
        data-toggle="modal"
        data-target="#nuevo"
        class="btn btn-success btn-rounded btn-sm"
      >
        <i class="fa fa-plus-circle"></i> Nueva entrada
      </button>
      <button
        v-if="!form_upload && perfil == 1"
        v-on:click="form_upload = !form_upload"
        data-toggle="modal"
        data-target="#subir"
        class="btn btn-success btn-rounded btn-sm ml-1"
      >
        <i class="fa fa-upload"></i> Subir entradas
      </button>
      <button
        v-on:click="load_search = !load_search"
        class="btn btn-info btn-rounded btn-sm ml-2"
      >
        <i class="fa fa-search"></i> Buscar entrada
      </button>
      <button
        v-on:click="load_filtro = !load_filtro"
        class="btn btn-info btn-rounded btn-sm ml-2"
      >
        <i class="fa fa-filter"></i> Filtrar
      </button>
    </div>
    <transition v-if="load_search" appear name="slide-fade">
      <div class="mb-3 mt-2">
        <input
          v-model="search"
          v-on:keyup="search_entrada"
          type="text"
          class="form-control my-form-control"
          placeholder="Buscar entrada por código..."
        />
      </div>
    </transition>
    <transition v-if="load_filtro" appear name="slide-fade">
      <div class="mb-3 mt-2">
        <div class="form-group">
          <label>Localidad</label>
          <select
            v-on:change="get_entradas"
            v-model="filtro.id_localidad"
            class="form-control"
          >
            <option
              v-for="(localidad, index_localidad) in localidades"
              v-bind:key="index_localidad"
              v-bind:value="localidad.id_localidad"
            >
              {{ localidad.localidad }}
            </option>
          </select>
        </div>
        <div class="text-right">
          <a
            v-on:click="
              (filtro.id_localidad = null),
                (load_filtro = false),
                get_entradas()
            "
            href="javascript:void(0)"
            class="text-danger"
          >
            <i class="fa fa-times"></i> Quitar filtros
          </a>
        </div>
      </div>
    </transition>
    <transition v-if="form_upload" appear name="slide-fade">
      <div v-if="form_upload" class="mt-3 mb-3">
        <div class="card card-body border bg-light">
          <div v-if="loading_upload" class="mb-3">
            <small class="text-center">Cargando archivo...</small><br />
            <div class="spinner-border text-info" role="status">
              <span class="sr-only">Cargando archivo...</span>
            </div>
          </div>
          <form enctype="multipart/form-data" v-on:submit.prevent="upload">
            <h5 class="text-primary font-weight-bold mt-2 mb-3">
              Subir entradas
            </h5>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="file-es" role="button">Seleccionar archivo</label>
                  <input
                    id="file-es-soporte"
                    type="file"
                    @change="onFileChange"
                    multiple
                  />
                  <small class="form-text text-muted">
                    Seleccionar archivo .csv.
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <button
                  :disabled="!hay_archivo"
                  type="submit"
                  class="btn btn-success btn-rounded btn-sm"
                >
                  <i class="fa fa-upload"></i> Subir
                </button>
                <a
                  v-on:click="form_upload = false"
                  class="btn btn-rounded btn-outline-dark btn-sm ml-2"
                >
                  Cancelar
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </transition>
    <transition appear name="slide-fade">
      <div
        v-if="msg_upload.type != null"
        class="alert alert-dismissible fade show"
        v-bind:class="{
          'alert-info': msg_upload.type,
          'alert-danger': !msg_upload.type,
        }"
      >
        <i v-if="msg_upload.type" class="fa fa-check-circle"></i>
        <i v-if="!msg_upload.type" class="fa fa-exclamation-circle"></i>
        <span v-html="msg_upload.text"></span>
        <button
          type="button"
          class="close h-100"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span><i class="mdi mdi-close"></i></span>
        </button>
      </div>
    </transition>
    <div class="table-responsive pt-3">
      <table class="table my_table table-bordered table-hover">
        <thead>
          <tr>
            <th class="text-center">#</th>
            <th>Localidad</th>
            <th>Código</th>
            <th>Ingreso</th>
            <th class="text-center">Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(entrada, index_entrada) in entradas"
            v-bind:key="index_entrada"
          >
            <td class="text-center" style="width: 5%">{{ entrada.num }}.</td>
            <td style="width: 30%">
              <span v-if="!edit || index_select != index_entrada">
                {{ entrada.localidad }}
              </span>
              <span v-else>
                <select v-model="entrada.id_localidad" class="form-control">
                  <option
                    v-for="(localidad, index_localidad) in localidades"
                    v-bind:key="index_localidad"
                    v-bind:value="localidad.id_localidad"
                  >
                    {{ localidad.localidad }}
                  </option>
                </select>
              </span>
            </td>
            <td style="width: 30%">
              <span v-if="!edit || index_select != index_entrada">
                {{ entrada.codigo }}
              </span>
              <input
                v-else
                v-model="entrada.codigo"
                class="form-control"
                type="text"
              />
            </td>
            <td>
              <span v-if="entrada.ingreso == null" class="text-muted">
                <i class="fa fa-exclamation-circle"></i> No hay registro de
                ingreso.
              </span>
              <span v-else>
                Ingreso registrado por:
                <strong>{{ entrada.ingreso.usuario }}</strong> <br />
                Hora de ingreso:
                <strong>{{ entrada.ingreso.hora }}</strong> <br />
                Fecha y hora de registro:
                <strong>{{ entrada.ingreso.fecha }}</strong>
              </span>
            </td>
            <td class="text-center" style="width: 10%">
              <div v-if="!edit || index_select != index_entrada">
                <div v-if="entrada.ingreso == null">
                  <button
                    v-on:click="load_editar(index_entrada)"
                    class="btn btn-primary btn-xs"
                  >
                    <i class="fa fa-edit"></i>
                  </button>
                  <button
                    v-on:click="load_eliminar(index_entrada)"
                    data-toggle="modal"
                    data-target="#eliminar"
                    class="btn btn-danger btn-xs"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
              <div v-else>
                <a
                  v-bind:id="'btn_save_' + index_entrada"
                  v-on:click="update_entrada"
                  class="btn btn-success btn-xs"
                >
                  <i class="fa fa-save"></i>
                </a>
                <a
                  v-on:click="edit = false"
                  class="btn btn-outline-dark btn-xs"
                >
                  <i class="fa fa-times"></i>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-4 mb-4" v-html="paginate"></div>
    <div class="modal fade" id="nuevo">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <form v-on:submit.prevent="set_entrada">
            <div class="modal-header">
              <h5 class="modal-title text-primary">Nueva entrada</h5>
              <button type="button" class="close" data-dismiss="modal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label>Localidad</label>
                <select v-model="nuevo.id_localidad" class="form-control">
                  <option
                    v-for="(localidad, index_localidad) in localidades"
                    v-bind:key="index_localidad"
                    v-bind:value="localidad.id_localidad"
                  >
                    {{ localidad.localidad }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label>Código</label>
                <input
                  v-model="nuevo.codigo"
                  type="text"
                  class="form-control my-form-control"
                  required
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-dark btn-rounded btn-sm light"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button type="submit" class="btn btn-success btn-sm btn-rounded">
                <i class="fa fa-save"></i> Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal fade" id="eliminar">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <form v-on:submit.prevent="delete_entrada">
            <div class="modal-header">
              <h5 class="modal-title text-danger">Eliminar entrada</h5>
              <button type="button" class="close" data-dismiss="modal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div v-if="msg_eliminar.type == null">
                <div class="text-danger">
                  <i class="fa fa-question-circle"></i> ¿Desea eliminar el
                  registro de la entrada <strong>{{ entrada.codigo }} </strong>?
                </div>
                <div class="text-center mt-3">
                  <button
                    type="submit"
                    class="btn btn-danger btn-sm btn-rounded"
                  >
                    Sí
                  </button>
                  <button
                    v-on:click="entrada = []"
                    type="button"
                    class="btn btn-outline-dark btn-rounded btn-sm light ml-1"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
              <div v-if="msg_eliminar.type && msg_eliminar.type != null">
                <div class="alert alert-info">
                  <i class="fa fa-check-circle"></i> La entrada ha sido
                  eliminada.
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Entradas",
  metaInfo: {
    title: "Entradas del evento - Taqui",
  },
  data() {
    return {
      loading: true,
      loading_upload: false,
      id_evento: null,
      page: null,
      search: null,
      entradas: [],
      paginate: null,
      localidades: [],
      edit: false,
      index_select: null,
      entrada: [],
      localidad: [],
      nuevo: {
        id_localidad: null,
        codigo: null,
      },
      msg_eliminar: {
        type: null,
        text: null,
      },
      load_search: false,
      load_filtro: false,
      filtro: {
        id_localidad: null,
      },
      form_upload: false,
      file: [],
      hay_archivo: false,
      msg_upload: {
        type: null,
        text: null,
      },
      subidas: [],
      subida_select: null,
    };
  },
  props: {
    evento: Object,
    perfil: String,
  },
  created: function () {
    this.get_entradas();
    this.get_localidades();
  },
  methods: {
    get_entradas: function () {
      this.id_evento = this.$route.params.id_evento;
      this.page = this.$route.params.page;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      let config = {
        headers: headers,
        params: {
          page: this.page,
          search: this.search,
          id_localidad: this.filtro.id_localidad,
        },
      };
      axios
        .get(this.$base_url + "eventos/entradas/" + this.id_evento, config, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status) {
            this.entradas = response.data.data;
            this.get_paginate(response.data.total_pages);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        })
        .catch()
        .finally();
    },
    get_paginate: function (tpages) {
      var page = this.page;

      if (page === undefined || page <= 0) {
        this.page = 1;
        page = this.page;
      }

      var adjacents = 4;

      var paginate = null;

      paginate = '<ul class="pagination justify-content-center text-center">';
      // previous label
      if (page == 1) {
        paginate +=
          "<li class='paginate_button page-item previous disabled'><a class='page-link disabled'> <i class='fa fa-chevron-left'></i> </a></li>";
      } else if (page == 2) {
        paginate +=
          "<li class='paginate_button page-item  previous'><a href='#/entradas/" +
          this.id_evento +
          "/1' class='page-link' href='javascript:void(0);'> <i class='fa fa-chevron-left'></i> </a></li>";
      } else {
        var page_ant = parseFloat(page) - 1;
        paginate +=
          "<li class='paginate_button page-item'><a href='#/entradas/" +
          this.id_evento +
          "/" +
          page_ant +
          "' class='page-link' href='javascript:void(0);'> <i class='fa fa-chevron-left'></i> </a></li>";
      }
      // first label
      if (page > parseFloat(adjacents) + 1) {
        paginate +=
          "<li class='paginate_button page-item'><a class='page-link' href='#/entradas/" +
          this.id_evento +
          "/1'>1</a></li>";
      }
      // interval
      if (page > parseFloat(adjacents) + 2) {
        paginate += "<li class='paginate_button page-item'> <a> ... </a> </li>";
      }
      // pages
      var pmin =
        page > adjacents ? parseFloat(page) - parseFloat(adjacents) : 1;
      var pmax =
        page < parseFloat(tpages) - parseFloat(adjacents)
          ? parseFloat(page) + parseFloat(adjacents)
          : tpages;

      for (var i = pmin; i <= pmax; i++) {
        if (i == page) {
          paginate +=
            "<li class='paginate_button page-item active'><a href='#/entradas/" +
            this.id_evento +
            "/" +
            i +
            "' class='page-link'>" +
            i +
            "</a></li>";
        } else if (i == 1) {
          paginate +=
            "<li class='paginate_button page-item'><a href='#/entradas/" +
            this.id_evento +
            "/" +
            i +
            "' class='page-link' href='javascript:void(0);'>" +
            i +
            "</a></li>";
        } else {
          paginate +=
            "<li class='paginate_button page-item'><a href='#/entradas/" +
            this.id_evento +
            "/" +
            i +
            "' class='page-link' href='javascript:void(0);'>" +
            i +
            "</a></li>";
        }
      }
      // interval
      if (page < parseFloat(tpages) - parseFloat(adjacents) - 1) {
        paginate += "<li class='paginate_button page-item'> <a> ... </a> </li>";
      }
      // last
      if (page < parseFloat(tpages) - parseFloat(adjacents)) {
        paginate +=
          "<li class='paginate_button page-item'><a  href='#/entradas/" +
          this.id_evento +
          "/" +
          tpages +
          "' class='page-link' href='javascript:void(0);'>" +
          tpages +
          "</a></li>";
      }
      // next
      if (page < tpages) {
        var page_next = parseFloat(page) + 1;
        paginate +=
          "<li class='paginate_button page-item next'><a  href='#/entradas/" +
          this.id_evento +
          "/" +
          page_next +
          "' class='page-link' href='javascript:void(0);'> <i class='fa fa-chevron-right'></i> </a></li>";
      } else {
        paginate +=
          "<li class='paginate_button page-item next disabled'><a class='page-link disabled'> <i class='fa fa-chevron-right'></i> </a></li>";
      }
      paginate += "</ul>";
      this.paginate = paginate;
    },
    get_localidades: function () {
      this.id_evento = this.$route.params.id_evento;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      this.page = this.$route.params.page;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      let config = {
        headers: headers,
      };
      axios
        .get(this.$base_url + "eventos/localidades/" + this.id_evento, config, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status) {
            this.localidades = response.data.data;
          }
        })
        .catch()
        .finally();
    },
    set_entrada: function () {
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      var params = {
        id_evento: this.id_evento,
        id_localidad: this.nuevo.id_localidad,
        codigo: this.nuevo.codigo,
      };
      const vm = this;
      axios
        .post(this.$base_url + "eventos/set_entrada", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.nuevo = {
              id_localidad: null,
              codigo: null,
            };
            vm.get_entradas();
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Nueva",
              text: "Se ha creado la nueva entrada.",
            });
          } else {
            vm.$notify({
              group: "foo",
              type: "error",
              title: "Nueva",
              text: response.data.message,
            });
            console.log(response);
          }
        })
        .catch((error) => {
          vm.$notify({
            group: "foo",
            type: "error",
            title: "Nueva",
            text: error,
          });
        });
    },
    load_editar: function (index) {
      this.entrada = this.entradas[index];
      this.index_select = index;
      this.edit = true;
    },
    update_entrada: function () {
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      var params = {
        id_entrada: this.entrada.id_entrada,
        id_localidad: this.entrada.id_localidad,
        codigo: this.entrada.codigo,
      };
      const vm = this;
      axios
        .put(this.$base_url + "eventos/update_entrada", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Entradas",
              text: "Se ha actualizado la entrada.",
            });
            vm.edit = false;
            vm.get_entradas();
          } else {
            vm.$notify({
              group: "foo",
              type: "error",
              title: "Entradas",
              text: response.data.message,
            });
            console.log(response);
          }
        })
        .catch((error) => {
          vm.$notify({
            group: "foo",
            type: "error",
            title: "Entradas",
            text: error,
          });
        });
    },
    load_eliminar: function (index) {
      this.entrada = this.entradas[index];
    },
    delete_entrada: function () {
      this.msg_eliminar = {
        type: null,
        text: null,
      };
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      var params = {
        id_entrada: this.entrada.id_entrada,
      };
      const vm = this;
      axios
        .put(this.$base_url + "eventos/eliminar_entrada", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.msg_eliminar = {
              type: true,
              text: "Entrada eliminada.",
            };
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Entradas",
              text: "Se ha eliminado la localidad.",
            });
            vm.entrada = [];
            vm.get_entradas();
          } else {
            vm.$notify({
              group: "foo",
              type: "error",
              title: "Entradas",
              text: response.data.message,
            });
            console.log(response);
          }
        })
        .catch((error) => {
          vm.$notify({
            group: "foo",
            type: "error",
            title: "Entradas",
            text: error,
          });
        });
    },
    search_entrada: function () {
      if (this.search != null) {
        if (this.search.length >= 3) {
          this.page = 1;
          this.get_entradas();
        } else {
          if (this.search == "" || this.search.length == 0) {
            this.search = null;
            this.get_entradas();
          }
        }
      }
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.hay_archivo = false;
        return;
      }
      this.file = files;
      this.hay_archivo = true;
    },
    upload: function () {
      this.hay_archivo = false;
      this.loading_upload = true;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      var formData = new FormData();
      formData.append("files", this.file[0]);
      formData.append("token", token_session);
      formData.append("id_evento", this.id_evento);
      const vm = this;
      axios
        .post(this.$base_url + "upload/entradas", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.status) {
            vm.hay_archivo = false;
            vm.msg_upload.type = true;
            vm.msg_upload.text =
              " Se importaron " + response.data.results + " entradas.";
            if (response.data.errores != null) {
              var errores = "";
              for (let error of response.data.errores) {
                errores = errores + "<li>" + error + "</li>";
              }
              vm.msg_upload.text =
                vm.msg_upload.text +
                "<br> <i class='fa fa-exclamation-circle'></i> Se encontraron los siguientes errores en la importación: " +
                errores;
            }
            vm.get_entradas();
            vm.get_localidades();
            vm.form_upload = false;
            vm.loading_upload = false;
          } else {
            vm.msg_upload = false;
            vm.loading_upload = false;
          }
        })
        .catch((error) => {
          vm.msg_upload = false;
          console.log(error);
          vm.loading_upload = false;
        });
    },
  },
  watch: {
    $route() {
      this.page = this.$route.params.page;
      this.get_entradas();
    },
  },
};
</script>